import React from 'react';

const PricingCard = ({ platform, color, prices }) => (
  <div className={`${color} p-4 rounded-lg text-white`}>
    <h3 className="text-xl font-bold mb-4">{platform}</h3>
    {prices.map(({ label, price }) => (
      <div key={label} className="flex justify-between border-b border-white/40 py-2">
        <span>{label}</span>
        <span>{price}</span>
      </div>
    ))}
  </div>
);

export default PricingCard;