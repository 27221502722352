import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "../../styles/tailwind.css";
import "../../styles/index.css";

export default function MotDePasseOublie() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <>
      <Helmet>
        <title>Mot de passe oublié ?</title>
        <meta
          name="description"
          content="Réinitialisez votre mot de passe en recevant un code de réinitialisation par email."
        />
      </Helmet>

      <div className="w-full min-h-screen bg-[#0A001B] flex">
        {/* Image toujours visible sur desktop */}
        {!isMobile && (
          <div className="hidden lg:block lg:w-2/5 h-screen sticky top-0 left-0">
            <img
              src="/images/img_rectangle_122.png"
              alt="Gamer Image"
              className="w-full h-full object-cover"
            />
          </div>
        )}

        {/* Section Formulaire */}
        <div
          className={`flex flex-col justify-center items-center w-full min-h-screen ${
            isMobile ? "px-6" : "lg:w-3/5 lg:px-16 ml-auto"
          }`}
        >
          <h1 className="text-white text-4xl font-bold mb-6">Mot de passe oublié ?</h1>

          {/* Formulaire */}
          <form className="w-full max-w-md flex flex-col gap-4">
            <input
              type="email"
              placeholder="Email"
              className="w-full h-12 rounded-md px-4 bg-gray-800 text-white border border-gray-700 focus:outline-none focus:border-orange-500"
            />

            {/* Bouton "Envoyer le code de réinitialisation" */}
            <button className="w-full h-12 rounded-md bg-orange-600 text-white font-semibold hover:bg-orange-700 transition duration-300">
              Envoyer le code de réinitialisation
            </button>
          </form>

          {/* Lien "Renvoyer le code" */}
          <p className="text-center text-gray-400 text-sm mt-4">
            Vous n’avez pas reçu de code ?{" "}
            <Link to="/renvoyer-code" className="text-orange-600 hover:text-orange-500 underline transition">
              Renvoyer
            </Link>
          </p>

          {/* Lien vers CGU */}
          <p className="mt-6 text-xs text-orange-600">
            Politique de confidentialité | CGU
          </p>
        </div>
      </div>
    </>
  );
}
