import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import '../../components/Sidebar.css';

export default function Reglages() {
  const [personalInfo, setPersonalInfo] = useState({
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
    adresse: "",
  });

  const [password, setPassword] = useState({
    current: "",
    new: "",
    confirm: "",
  });

  const [emailNotifications, setEmailNotifications] = useState({
    miseAJour: false,
    messageRecu: false,
    nouveaute: false,
    offres: false,
    newsletter: false,
  });

  const [pushNotifications, setPushNotifications] = useState(false);

  const [confidentialite, setConfidentialite] = useState({
    profilVisibility: "public",
    searchControl: false,
    partageDonnees: false,
    gestionCookies: false,
  });

  const [socialConnections, setSocialConnections] = useState({
    facebook: false,
    twitter: false,
  });

  const [securite, setSecurite] = useState({
    alertes: false,
    accessibility: "normal",
  });

  const [avances, setAvances] = useState({
    experimental: false,
  });

  const handleChange = (setter) => (e) => {
    const { name, type, value, checked } = e.target;
    setter((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Données soumises :", {
      personalInfo,
      password,
      emailNotifications,
      pushNotifications,
      confidentialite,
      socialConnections,
      securite,
      avances,
    });
  };

  return (
    <div className="flex min-h-screen bg-[#0A001B] text-white">
      <Sidebar />
      <div className="w-full p-10 overflow-auto">
        <h1 className="text-3xl font-bold mb-8">Réglages</h1>
        <form onSubmit={handleSubmit} className="space-y-8">
          
          {/* Réglages du Compte */}
          <div className="bg-[#18122B] p-6 rounded-lg shadow-sm">
            <h2 className="text-2xl font-bold mb-4">Réglages du Compte</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {["nom", "prenom", "email", "telephone", "adresse"].map((field, index) => (
                <div key={field} className={field === "adresse" ? "md:col-span-2" : ""}>
                  <label className="block mb-1 font-semibold capitalize">{field}</label>
                  <input
                    type={field === "email" ? "email" : "text"}
                    name={field}
                    value={personalInfo[field]}
                    onChange={handleChange(setPersonalInfo)}
                    className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                    placeholder={`Votre ${field}`}
                  />
                </div>
              ))}
            </div>

            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-2">Changement de mot de passe</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {["current", "new", "confirm"].map((field, idx) => (
                  <div key={field} className={field === "confirm" ? "md:col-span-2" : ""}>
                    <label className="block mb-1 font-semibold capitalize">
                      {field === "current"
                        ? "Mot de passe actuel"
                        : field === "new"
                        ? "Nouveau mot de passe"
                        : "Confirmer le nouveau mot de passe"}
                    </label>
                    <input
                      type="password"
                      name={field}
                      value={password[field]}
                      onChange={handleChange(setPassword)}
                      className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                      placeholder="••••••••"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-2">Historique et sessions</h3>
              <p className="text-sm text-gray-300 mb-2">
                Voir vos connexions récentes et gérer vos sessions actives.
              </p>
              <button type="button" className="px-4 py-2 bg-purple-600 rounded-md hover:bg-purple-700">
                Voir l'historique et gérer les sessions
              </button>
            </div>
          </div>

          {/* Notifications */}
          <div className="bg-[#18122B] p-6 rounded-lg shadow-sm">
            <h2 className="text-2xl font-bold mb-4">Notifications</h2>

            <div className="mb-6 space-y-2">
              {Object.keys(emailNotifications).map((key) => (
                <div className="flex items-center" key={key}>
                  <input
                    type="checkbox"
                    name={key}
                    checked={emailNotifications[key]}
                    onChange={handleChange(setEmailNotifications)}
                    className="mr-2"
                  />
                  <label>{key}</label>
                </div>
              ))}
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                name="pushNotifications"
                checked={pushNotifications}
                onChange={(e) => setPushNotifications(e.target.checked)}
                className="mr-2"
              />
              <label>Notifications push / in-app</label>
            </div>
          </div>

          {/* Confidentialité */}
          <div className="bg-[#18122B] p-6 rounded-lg shadow-sm">
            <h2 className="text-2xl font-bold mb-4">Confidentialité</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block mb-1 font-semibold">Visibilité du profil</label>
                <select
                  name="profilVisibility"
                  value={confidentialite.profilVisibility}
                  onChange={handleChange(setConfidentialite)}
                  className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                >
                  <option value="public">Public</option>
                  <option value="private">Privé</option>
                  <option value="conditional">Accessible sous conditions</option>
                </select>
              </div>
              {["searchControl", "partageDonnees", "gestionCookies"].map((key) => (
                <div className="flex items-center" key={key}>
                  <input
                    type="checkbox"
                    name={key}
                    checked={confidentialite[key]}
                    onChange={handleChange(setConfidentialite)}
                    className="mr-2"
                  />
                  <label>{key}</label>
                </div>
              ))}
            </div>
          </div>

          {/* Connexions sociales */}
          <div className="bg-[#18122B] p-6 rounded-lg shadow-sm">
            <h2 className="text-2xl font-bold mb-4">Connexions sociales</h2>
            <div className="space-y-2">
              {["facebook", "twitter"].map((platform) => (
                <div className="flex items-center" key={platform}>
                  <input
                    type="checkbox"
                    name={platform}
                    checked={socialConnections[platform]}
                    onChange={handleChange(setSocialConnections)}
                    className="mr-2"
                  />
                  <label>{platform}</label>
                </div>
              ))}
            </div>
          </div>

          {/* Sécurité */}
          <div className="bg-[#18122B] p-6 rounded-lg shadow-sm">
            <h2 className="text-2xl font-bold mb-4">Sécurité</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="alertes"
                  checked={securite.alertes}
                  onChange={handleChange(setSecurite)}
                  className="mr-2"
                />
                <label>Alertes de sécurité</label>
              </div>
              <div>
                <label className="block mb-1 font-semibold">Accessibilité</label>
                <select
                  name="accessibility"
                  value={securite.accessibility}
                  onChange={handleChange(setSecurite)}
                  className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                >
                  <option value="small">Petite</option>
                  <option value="normal">Normale</option>
                  <option value="large">Grande</option>
                </select>
              </div>
            </div>
          </div>

          {/* Avancés */}
          <div className="bg-[#18122B] p-6 rounded-lg shadow-sm">
            <h2 className="text-2xl font-bold mb-4">Avancés & Support</h2>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="experimental"
                checked={avances.experimental}
                onChange={handleChange(setAvances)}
                className="mr-2"
              />
              <label>Options expérimentales</label>
            </div>
          </div>

          {/* Bouton enregistrer */}
          <div className="text-right">
            <button type="submit" className="bg-green-600 px-6 py-2 rounded font-bold hover:bg-green-700">
              Enregistrer les modifications
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
