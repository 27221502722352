import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "../../styles/tailwind.css";
import "../../styles/index.css";

export default function ConnexionReseauxSociauxPage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Vérification de la taille de l'écran
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    checkScreenSize(); // Exécute au chargement
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <>
      <Helmet>
        <title>Connexion aux Réseaux Sociaux</title>
        <meta name="description" content="Connecte tes réseaux sociaux à ton compte." />
      </Helmet>

      <div className="w-full min-h-screen bg-[#0A001B] flex">
        {/* Image fixe sur desktop */}
        {!isMobile && (
          <div className="hidden lg:block lg:w-2/5 h-screen sticky top-0 left-0">
            <img
              src="/images/img_rectangle_122.png"
              alt="Gamer Image"
              className="w-full h-full object-cover"
            />
          </div>
        )}

        {/* Contenu principal */}
        <div
          className={`flex flex-col justify-center items-center w-full min-h-screen ${
            isMobile ? "px-6" : "lg:w-3/5 lg:px-16 ml-auto"
          }`}
        >
          <h1 className="text-white text-3xl font-bold mb-8 text-center">
            Connexion aux Réseaux Sociaux
          </h1>

          {/* Icônes des réseaux sociaux */}
          <div className="grid grid-cols-3 gap-6 mb-8">
            <button className="w-24 h-24 flex items-center justify-center bg-white rounded-lg shadow-md hover:scale-105 transition">
              <img src="/images/twitch.png" alt="Twitch" className="h-12" />
            </button>
            <button className="w-24 h-24 flex items-center justify-center bg-white rounded-lg shadow-md hover:scale-105 transition">
              <img src="/images/instagram.png" alt="Instagram" className="h-12" />
            </button>
            <button className="w-24 h-24 flex items-center justify-center bg-white rounded-lg shadow-md hover:scale-105 transition">
              <img src="/images/youtube.png" alt="YouTube" className="h-12" />
            </button>
            <button className="w-24 h-24 flex items-center justify-center bg-white rounded-lg shadow-md hover:scale-105 transition">
              <img src="/images/twitter.png" alt="Twitter (X)" className="h-12" />
            </button>
            <button className="w-24 h-24 flex items-center justify-center bg-white rounded-lg shadow-md hover:scale-105 transition">
              <img src="/images/facebook.png" alt="Facebook" className="h-12" />
            </button>
            <button className="w-24 h-24 flex items-center justify-center bg-white rounded-lg shadow-md hover:scale-105 transition">
              <img src="/images/tiktok.png" alt="TikTok" className="h-12" />
            </button>
          </div>

          {/* Bouton Continuer */}
          <Link to="/dashboard">
            <button className="w-56 h-12 rounded-md bg-orange-600 text-white font-semibold hover:bg-orange-700 transition">
              Continuer
            </button>
          </Link>

          <p className="mt-6 text-xs text-orange-600">
            Politique de confidentialité | CGU
          </p>
        </div>
      </div>
    </>
  );
}
