import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "../../styles/tailwind.css";
import "../../styles/index.css";

export default function NouveauMotDePassePage() {
  const [isMobile, setIsMobile] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Validation du mot de passe
  const validatePassword = (pwd) => {
    const hasMinLength = pwd.length >= 8;
    const hasNumber = /\d/.test(pwd);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pwd);
    return hasMinLength && hasNumber && hasSpecialChar;
  };

  // Gestion de la mise à jour du mot de passe
  const handleUpdatePassword = () => {
    if (!validatePassword(password)) {
      setError("Le mot de passe ne respecte pas les critères de sécurité.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    setError("");
    alert("Mot de passe mis à jour avec succès !");
    navigate("/connexion"); // Redirection vers la connexion
  };

  return (
    <>
      <Helmet>
        <title>Nouveau mot de passe</title>
        <meta name="description" content="Mettez à jour votre mot de passe en toute sécurité." />
      </Helmet>

      <div className="w-full min-h-screen bg-[#0A001B] flex">
        {/* Image fixe sur desktop */}
        {!isMobile && (
          <div className="hidden lg:block lg:w-2/5 h-screen sticky top-0 left-0">
            <img
              src="/images/img_rectangle_122.png"
              alt="Gamer Image"
              className="w-full h-full object-cover"
            />
          </div>
        )}

        {/* Contenu principal */}
        <div className={`flex flex-col justify-center items-center w-full min-h-screen ${isMobile ? "px-6" : "lg:w-3/5 lg:px-16 ml-auto"}`}>
          <h1 className="text-white text-3xl font-bold mb-6 text-center">
            Créez un nouveau mot de passe !
          </h1>

          {/* Champs du mot de passe */}
          <form className="w-full max-w-md space-y-3">
            <input
              type="password"
              placeholder="Nouveau Mot de Passe"
              className="w-full h-12 rounded-md px-4 bg-gray-800 text-white border border-gray-700"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirmez le Mot de Passe"
              className="w-full h-12 rounded-md px-4 bg-gray-800 text-white border border-gray-700"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            {/* Message d'erreur */}
            {error && <p className="text-red-500 text-sm text-center">{error}</p>}

            {/* Critères de mot de passe */}
            <div className="text-gray-400 text-sm mb-4">
              <p>Le mot de passe doit contenir minimum :</p>
              <ul className="list-disc list-inside">
                <li>8 caractères</li>
                <li>1 chiffre</li>
                <li>1 caractère spécial</li>
              </ul>
            </div>

            {/* Bouton de mise à jour */}
            <button
              className="w-full h-12 rounded-md bg-orange-600 text-white font-semibold hover:bg-orange-700 transition"
              onClick={handleUpdatePassword}
              type="button"
            >
              Mettre à jour
            </button>
          </form>

          <p className="mt-6 text-xs text-orange-600">
            Politique de confidentialité | CGU
          </p>
        </div>
      </div>
    </>
  );
}
