import React from 'react';

const CollaborationCard = ({ src, alt, name }) => (
  <div className="relative bg-cover bg-center bg-no-repeat rounded-lg h-64 shadow-lg" style={{ backgroundImage: `url(${src})` }}>
    <div className="absolute bottom-0 w-full bg-black bg-opacity-50 p-2 text-center">
      <p className="text-lg font-bold">{name}</p>
    </div>
  </div>
);

export default CollaborationCard;