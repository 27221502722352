import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Customized
} from "recharts";

const data = [
  { name: "Hommes", value: 75 },
  { name: "Femmes", value: 25 },
];

const COLORS = ["#22d3ee", "#a855f7"];

// Composant personnalisé pour afficher "75%" au centre, avec une taille réduite de 25%
const renderCenterText = (props) => {
  const { cx, cy } = props;
  return (
    <text
      x={cx}
      y={cy}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fill: "#22d3ee",
        fontWeight: "bold",
        fontSize: "54px", // réduit de 25%
      }}
    >
      75%
    </text>
  );
};

export default function DonutChart() {
  return (
    <div style={{ width: "100%", height: 400, position: "relative", color: "white" }}>
      <ResponsiveContainer>
        <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 40 }}>
          <Tooltip />
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius="60%"  // réduit de 25% (80% -> 60%)
            innerRadius="45%"  // réduit de 25% (60% -> 45%)
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
          </Pie>
          {/* Texte central personnalisé */}
          <Customized component={renderCenterText} />
          {/* Légende en dessous du graphique avec icônes de type cercle */}
          <Legend
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            iconType="circle"
            wrapperStyle={{
              fontSize: "1rem",
              fontWeight: "bold",
              color: "white",
              marginTop: "10px",
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
