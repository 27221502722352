import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";

export default function Dashboard() {
  const [selectedPeriod, setSelectedPeriod] = useState("30 derniers jours");

  const handlePeriodChange = (period) => setSelectedPeriod(period);

  const periods = [
    "15 derniers jours",
    "30 derniers jours",
    "3 derniers mois",
    "6 derniers mois",
    "Depuis le début d'année",
    "1 an",
    "3 ans",
  ];

  const tabs = ["Général", "Instagram", "Facebook", "TikTok", "Youtube", "Twitch", "X"];

  return (
    <div className="flex min-h-screen bg-[#0A001B] text-white">
      <Sidebar />

      {/* Contenu principal avec menu horizontal fixe */}
      <div className="flex-1 relative">
        {/* 🔹 Barre horizontale fixe */}
        <div className="sticky top-0 z-50 bg-[#18122B] px-6 py-4 flex justify-between items-center border-b border-[#2A223F] shadow-md">
          <div className="flex flex-wrap gap-2">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`px-4 py-2 rounded-md font-semibold ${
                  tab === "Général" ? "bg-purple-600 text-white" : "hover:bg-[#0A001B]"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>

          <select
            value={selectedPeriod}
            onChange={(e) => handlePeriodChange(e.target.value)}
            className="bg-[#0A001B] border border-white text-white px-4 py-2 rounded-md font-semibold"
          >
            {periods.map((period) => (
              <option key={period} value={period}>
                {period}
              </option>
            ))}
          </select>
        </div>

        {/* Contenu scrollable */}
        <div className="p-6 md:p-10">
          {/* Section : Mes comptes */}
          <h2 className="text-3xl font-bold mb-2">Mes comptes</h2>
          <p className="text-sm text-gray-300 mb-4">
            Statistiques générales sur la période {selectedPeriod}
          </p>

          {/* Cartes de réseaux sociaux */}
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 mb-8">
            {[
              { name: "Instagram", stat: "30 M", diff: "+2%", img: "instagram.png" },
              { name: "Facebook", stat: "3 M", diff: "+2.2%", img: "facebook.png" },
              { name: "TikTok", stat: "35 M", diff: "+1.4%", img: "tiktok.png" },
              { name: "YouTube", stat: "60 M", diff: "+1%", img: "youtube.png" },
              { name: "Twitch", stat: "300 K", diff: "-1%", img: "twitch.png", isNegative: true },
            ].map(({ name, stat, diff, img, isNegative }) => (
              <div
                key={name}
                className="bg-[#18122B] rounded-lg p-4 shadow-sm flex flex-col items-center"
              >
                <img src={`/images/${img}`} alt={name} className="w-12 h-12 mb-2" />
                <p className="font-semibold">@MrBeast</p>
                <p className="text-2xl font-bold mt-1">{stat}</p>
                <p
                  className={`text-sm mt-1 ${
                    isNegative ? "text-red-500" : "text-green-400"
                  }`}
                >
                  {diff}
                </p>
              </div>
            ))}
          </div>

          {/* Graphique évolution */}
          <h2 className="text-3xl font-bold mb-2">Évolution du nombre d'abonnés</h2>
          <img
            src="/images/Chartabo.png"
            alt="Évolution abonnés"
            className="w-full h-auto rounded-lg shadow-md mb-8"
          />

          {/* 4 Stats principales */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <StatBox title="Nouveaux Abonnés" value="100 115" />
            <StatBox title="Taux d'Engagement" value="13.9%" />
            <GenderBox />
            <AgeBox />
          </div>
        </div>
      </div>
    </div>
  );
}

const StatBox = ({ title, value }) => (
  <div className="bg-[#18122B] rounded-lg p-6 shadow-sm flex flex-col items-center justify-center min-h-[180px]">
    <h3 className="text-3xl font-bold mb-2 text-center">{title}</h3>
    <p className="text-6xl font-bold">{value}</p>
  </div>
);

const GenderBox = () => (
  <div className="bg-[#18122B] rounded-lg p-6 shadow-sm flex flex-col justify-center min-h-[180px]">
    <h3 className="text-3xl font-bold mb-4 text-center">Répartition Sexe</h3>
    <div className="flex items-center justify-center">
      <div className="flex flex-col items-center mr-4">
        <div className="flex items-center gap-1 mb-2">
          <span className="w-3 h-3 rounded-full bg-purple-500" />
          <span className="text-sm">Femmes</span>
        </div>
        <p className="text-4xl font-bold">28%</p>
      </div>
      <div className="h-16 w-[1px] bg-gray-400 mx-2" />
      <div className="flex flex-col items-center ml-4">
        <div className="flex items-center gap-1 mb-2">
          <span className="w-3 h-3 rounded-full bg-cyan-400" />
          <span className="text-sm">Hommes</span>
        </div>
        <p className="text-4xl font-bold">72%</p>
      </div>
    </div>
  </div>
);

const AgeBox = () => (
  <div className="bg-[#18122B] rounded-lg p-6 shadow-sm flex flex-col justify-center min-h-[180px]">
    <h3 className="text-3xl font-bold mb-4 text-center">Répartition d'âge</h3>
    <div className="flex items-center justify-center space-x-6">
      <AgeItem color="bg-blue-500" label="13-17 ans" value="40%" />
      <AgeItem color="bg-purple-500" label="18-25 ans" value="20%" />
    </div>
    <div className="flex items-center justify-center space-x-6 mt-4">
      <AgeItem color="bg-green-500" label="26-35 ans" value="30%" />
      <AgeItem color="bg-orange-500" label="35 ans et +" value="10%" />
    </div>
  </div>
);

const AgeItem = ({ color, label, value }) => (
  <div className="flex flex-col items-center">
    <div className="flex items-center gap-1 mb-2">
      <span className={`w-3 h-3 rounded-full ${color}`} />
      <span className="text-sm">{label}</span>
    </div>
    <p className="text-4xl font-bold">{value}</p>
  </div>
);
