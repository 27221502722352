import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "../../styles/tailwind.css";
import "../../styles/index.css";

export default function AjoutInfosPage() {
  const [activeTab, setActiveTab] = useState("creator");
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // Liste des 16 centres d’intérêt
  const interestOptions = [
    "Jeux-Vidéo", "Musique", "Sport", "Voyage",
    "Cuisine", "Technologie", "Mode", "Cinéma",
    "Lecture", "Fitness", "Business", "Nature",
    "Photographie", "Éducation", "Science", "Gaming"
  ];

  // Fonction pour sélectionner un centre d’intérêt (max 4)
  const toggleInterest = (interest) => {
    if (selectedInterests.includes(interest)) {
      setSelectedInterests(selectedInterests.filter(i => i !== interest));
    } else if (selectedInterests.length < 4) {
      setSelectedInterests([...selectedInterests, interest]);
    }
  };

  return (
    <>
      <Helmet>
        <title>Informations - Inscription</title>
        <meta name="description" content="Ajoutez vos informations pour personnaliser votre profil." />
      </Helmet>

      <div className="w-full min-h-screen bg-[#0A001B] flex">
        {/* Section Image */}
        <div className="hidden lg:flex lg:w-2/5 h-full rounded-br-3xl rounded-tr-3xl overflow-hidden">
          <img
            src="/images/img_rectangle_122.png"
            alt="Gamer Image"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Section Formulaire */}
        <div className="flex flex-col justify-center items-center w-full lg:w-3/5 px-6 lg:px-16">
          {/* Titre */}
          <h1 className="text-white text-4xl font-bold mb-6">Informations</h1>

          {/* Boutons Switch (Créateur / Entreprise) */}
          <div className="flex gap-4 mb-6">
            <button
              className={`px-6 py-2 rounded-md font-semibold ${
                activeTab === "creator" ? "bg-orange-600 text-white" : "bg-transparent border border-orange-600 text-orange-600"
              }`}
              onClick={() => setActiveTab("creator")}
            >
              Créateur de contenu
            </button>
            <button
              className={`px-6 py-2 rounded-md font-semibold ${
                activeTab === "entreprise" ? "bg-orange-600 text-white" : "bg-transparent border border-orange-600 text-orange-600"
              }`}
              onClick={() => setActiveTab("entreprise")}
            >
              Entreprise
            </button>
          </div>

          {/* Formulaire */}
          <form className="w-full max-w-md space-y-4">
            {/* Nom du créateur */}
            <input
              type="text"
              placeholder="Nom de Créateur"
              className="w-full h-12 rounded-md px-4 bg-gray-800 text-white border border-gray-700"
            />

            {/* Centre d'intérêt avec bouton "Ajouter" */}
            <div className="flex items-center gap-2">
              <input
                type="text"
                placeholder="Centre d'intérêt"
                value={selectedInterests.join(", ")}
                readOnly
                className="w-full h-12 rounded-md px-4 bg-gray-800 text-white border border-gray-700 cursor-not-allowed"
              />
              <button
                type="button"
                className="px-4 py-2 bg-orange-600 text-white rounded-md text-sm"
                onClick={() => setIsModalOpen(true)}
              >
                Ajouter
              </button>
            </div>

            {/* Biographie */}
            <div>
              <label className="text-white font-semibold">Biographie</label>
              <textarea
                placeholder="Youtuber de 24 ans, mes vidéos se concentrent sur les jeux vidéo..."
                className="w-full h-24 rounded-md px-4 bg-gray-800 text-white border border-gray-700 mt-1"
              />
            </div>

            {/* Ajout de photo de profil avec "+" centré */}
            <div className="flex flex-col items-center">
              <p className="text-white mb-2">Ajoutez une photo de profil :</p>
              <div className="w-24 h-24 border-2 border-orange-600 flex items-center justify-center rounded-md text-white text-lg relative">
                <span className="text-3xl font-bold">+</span>
              </div>
            </div>

            {/* Bouton Continuer */}
            <button
              className="w-full h-12 rounded-md bg-orange-600 text-white font-semibold hover:bg-orange-700"
              onClick={(e) => {
                e.preventDefault();
                navigate("/connexion-rs");
              }}
            >
              Continuer
            </button>
          </form>

          {/* Lien Politique de confidentialité */}
          <p className="mt-6 text-xs text-orange-600">Politique de confidentialité | CGU</p>
        </div>
      </div>

      {/* Modal Popup */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-[#1A1A2E] p-6 rounded-lg w-96">
            <h2 className="text-white text-xl font-semibold mb-4">Choisissez 4 centres d'intérêt</h2>
            
            <div className="grid grid-cols-2 gap-3">
              {interestOptions.map((interest) => (
                <button
                  key={interest}
                  className={`px-4 py-2 rounded-md ${
                    selectedInterests.includes(interest)
                      ? "bg-orange-600 text-white"
                      : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                  }`}
                  onClick={() => toggleInterest(interest)}
                >
                  {interest}
                </button>
              ))}
            </div>

            {/* Bouton Valider */}
            <button
              className="mt-4 w-full bg-orange-600 text-white py-2 rounded-md"
              onClick={() => setIsModalOpen(false)}
            >
              Valider
            </button>
          </div>
        </div>
      )}
    </>
  );
}
