import React from "react";
import Sidebar from "../../components/Sidebar";
import Button from "../../components/Button2";
import StatCard from "../../components/StatCard";
import CollaborationCard from "../../components/CollaborationCard";
import PricingCard from "../../components/PricingCard";
import DonutChart from "../../components/Graphs/Kitmedia/DonutChart";
import BarChartHorizontal from "../../components/Graphs/Kitmedia/BarChartHorizontal";
import PieOfPieChart from "../../components/Graphs/Kitmedia/PieOfPieChart";
import {
  platforms,
  instagramPrices,
  youtubePrices,
  twitchPrices,
  tiktokPrices,
  facebookPrices,
} from "../../data/PlatformData";
import "./KitMedia.css"; // Importez le fichier CSS

export default function KitMedia() {
  return (
    <div className="flex min-h-screen bg-[#0A001B] text-white">
      {/* Sidebar (couleur d’origine) */}
      <Sidebar />

      {/* Contenu principal avec marges */}
      <main className="main-background-container flex-1 px-8 py-6 overflow-auto">
        <div className="background-container">
          {/* HEADER */}
          <header className="flex justify-between items-center mb-8">
            {/* Boutons à gauche */}
            <div className="flex space-x-4">
              <Button
                className="border-2 border-white bg-[#231769] rounded-[0.575rem]"
                text="📤 Partager"
              />
              <Button
                className="border-2 border-white bg-[#231769] rounded-[0.575rem]"
                text="📥 Télécharger PDF"
              />
              <Button
                className="border-2 border-white bg-[#231769] rounded-[0.575rem]"
                text="👀 Vue Publique"
              />
            </div>

            {/* Bouton Éditer & Logo */}
            <div className="flex items-center gap-4">
              <Button className="bg-pink-500" text="✏️ Éditer" />
              <span className="text-xl font-bold">Reachy</span>
            </div>
          </header>

          {/* SECTION PRINCIPALE */}
          <section className="flex flex-row gap-10">
            {/* Colonne 1 : Infos du créateur */}
            <div className="w-3/4 space-y-6">
              <CreatorInfo
                name="MrBeast"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
              />
              <Interests />
              <PlatformsStatistics />
              <Button
                className="bg-orange-600 hover:bg-orange-700 mt-6"
                text="Contacter"
              />
            </div>

            {/* Colonne 2 : Photo de profil */}
            <div className="w-1/4 profile-image-container flex items-start justify-center">
              <ProfileImage src="/images/mrbeast-profile.jpg" alt="Profil" />
            </div>
          </section>
        </div>

        {/* TITRE : STATISTIQUES */}
        <SectionTitle title="Statistiques" />

        {/* 1ère rangée : 3 blocs (Répartition Sexe, Age, Géographique) */}
        <div className="grid grid-cols-3 gap-6 mb-6">
          <StatCard icon="👫" title="Répartition Sexe">
            <DonutChart />
          </StatCard>
          <StatCard icon="👨‍🦯" title="Répartition Age">
            <BarChartHorizontal />
          </StatCard>
          <StatCard icon="🌍" title="Répartition Géographique">
            <PieOfPieChart />
          </StatCard>
        </div>

        {/* 2ème rangée : 3 blocs (Audience Totale, Plateforme Principale, Nombre total d'abonnés) */}
        <div className="grid grid-cols-3 gap-6 mb-10">
          <StatCard icon="👥" title="Audience Totale">
            <TotalAudience />
          </StatCard>
          <StatCard icon="👑" title="Plateforme Principale">
            <MainPlatform />
          </StatCard>
          <StatCard icon="🌐" title="Nombre total d'abonnés">
            <TotalSubscribers />
          </StatCard>
        </div>

        {/* SECTION : Mes Collaborations (4 images) */}
        <SectionTitle title="Mes Collaborations" />
        <div className="grid grid-cols-4 gap-4 mb-10">
          <CollaborationCard src="/images/lunchly.png" alt="Lunchly" name="Lunchly" />
          <CollaborationCard src="/images/fortnite.png" alt="Fortnite" name="Fortnite" />
          <CollaborationCard src="/images/nerf.png" alt="Nerf" name="Nerf" />
          <CollaborationCard src="/images/feastables.png" alt="Feastables" name="Feastables" />
        </div>

        {/* GRILLE DE TARIFS (5 colonnes, couleurs unies foncées) */}
        <SectionTitle title="Grille de Tarifs" />
        <div className="grid grid-cols-5 gap-4">
          <PricingCard platform="Instagram" color="bg-[#e59866]" prices={instagramPrices} />
          <PricingCard platform="YouTube" color="bg-[#f1948a]" prices={youtubePrices} />
          <PricingCard platform="Twitch" color="bg-[#c39bd3]" prices={twitchPrices} />
          <PricingCard platform="TikTok" color="bg-[#7dcea0]" prices={tiktokPrices} />
          <PricingCard platform="Facebook" color="bg-[#85c1e9]" prices={facebookPrices} />
        </div>
      </main>
    </div>
  );
}

const CreatorInfo = ({ name, description }) => (
  <div>
    <h1 className="text-5xl font-bold mb-3">{name}</h1>
    <p className="text-gray-300 leading-relaxed">{description}</p>
  </div>
);

const Interests = () => (
  <div className="interests flex flex-wrap gap-4">
    {["Jeux-Vidéo", "Divertissement", "Testing", "Philanthrope"].map((interest) => (
      <span key={interest}>{interest}</span>
    ))}
  </div>
);

const PlatformsStatistics = () => (
  <div className="max-w-xl">
    <div className="grid grid-cols-6 gap-3">
      {platforms.map(({ src, alt, stats }) => (
        <div key={alt} className="flex flex-col items-center">
          <img src={src} alt={alt} className="object-contain w-14 h-14 mb-2" />
          <p className="text-base font-bold">{stats.followers}</p>
          <p className="text-sm text-gray-400">{stats.engagementRate}</p>
        </div>
      ))}
    </div>
  </div>
);

const ProfileImage = ({ src, alt }) => (
  <div className="profile-image-container">
    <img src={src} alt={alt} />
  </div>
);

const SectionTitle = ({ title }) => (
  <h2 className="text-3xl font-bold mt-10 mb-4">{title}</h2>
);

const StatItem = ({ label }) => (
  <p className="text-3xl font-bold text-gray-300">{label}</p>
);

const AgeDistribution = () => (
  <div className="text-gray-300 space-y-1">
    {[
      { ageRange: "13-17 ans", percentage: "20%" },
      { ageRange: "18-24 ans", percentage: "50%" },
      { ageRange: "25-34 ans", percentage: "17%" },
      { ageRange: "35+ ans", percentage: "13%" },
    ].map(({ ageRange, percentage }) => (
      <p key={ageRange} className="text-lg">
        {ageRange} : {percentage}
      </p>
    ))}
  </div>
);

const GeoDistribution = () => (
  <div className="flex justify-between gap-4 text-gray-300 mt-2">
    <div>
      <p className="text-base font-semibold mb-1">Pays</p>
      <p>France : 86%</p>
      <p>Belgique : 4%</p>
      <p>Canada : 2%</p>
    </div>
    <div>
      <p className="text-base font-semibold mb-1">Ville</p>
      <p>Paris : 80%</p>
      <p>Lyon : 10%</p>
      <p>Bordeaux : 4%</p>
    </div>
  </div>
);

const TotalAudience = () => (
  <div className="flex flex-col items-center justify-center">
    <p className="text-6xl font-bold">72 M</p>
    <p className="text-sm text-gray-300 mt-1">
      de personnes atteintes ces 30 derniers jours
    </p>
  </div>
);

const MainPlatform = () => (
  <div className="flex items-center justify-center gap-3 mt-2">
    <img
      src="/images/red-youtube-logo-symbol-701751694792489qxkccchok1.png"
      alt="YouTube"
      className="w-12 h-12 object-contain"
    />
    <p className="text-6xl font-bold">2 M</p>
    <p className="text-sm text-gray-300 mt-2 text-center">
      de vues ces 30 derniers jours
    </p>
  </div>
);

const TotalSubscribers = () => (
  <div className="flex flex-col items-center justify-center">
    <p className="text-6xl font-bold">725 M</p>
    <p className="text-sm text-gray-300 mt-1">
      de personnes abonnées à l'ensemble des Réseaux Sociaux
    </p>
  </div>
);