import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaRocket, FaThLarge, FaChartBar, FaUser, FaCog, FaQuestionCircle, FaSignOutAlt, FaBars
} from "react-icons/fa";
import './Sidebar.css'; // Importez le fichier CSS

export default function Sidebar() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  // Définition des éléments du menu
  const menuItems = [
    { name: "Tableau de bord", path: "/dashboard", icon: <FaThLarge /> },
    { name: "Mon Kit Média", path: "/kitmedia", icon: <FaRocket /> },
  //{ name: "Mes Opportunités", path: "/opportunites", icon: <FaChartBar /> },//
    { name: "Réglages", path: "/reglages", icon: <FaCog /> },
    { name: "Assistance", path: "/assistance", icon: <FaQuestionCircle /> },
  ];

  // Toggle sidebar
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Bouton hamburger pour mobile */}
      <div className="md:hidden flex items-center p-4">
        <button onClick={toggleSidebar} className="text-white">
          <FaBars className="text-2xl" />
        </button>
      </div>

      {/* Sidebar */}
      <div className={`fixed inset-y-0 left-0 transform ${isOpen ? "translate-x-0" : "-translate-x-full"} 
        md:relative md:translate-x-0 transition-transform duration-200 ease-in-out w-56 bg-sidebarBg text-white h-screen flex flex-col p-6 justify-between`}>
        
        {/* Logo */}
        <div className="text-xl font-bold text-white text-center mb-6">Reachy</div>

        {/* Profil utilisateur */}
        <div className="flex flex-col items-center mb-6">
          <img
            src="/images/mrbeast-profile.jpg"
            alt="MrBeast"
            className="w-20 h-20 rounded-full border-2 border-white mb-2"
          />
          <span className="text-md font-semibold">MrBeast</span>
        </div>

        {/* Navigation */}
        <nav className="space-y-2">
          {menuItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className={`flex items-center px-3 py-2 rounded-md text-sm transition ${
                location.pathname === item.path
                  ? "bg-orange-600 text-white font-bold"
                  : "text-gray-300 hover:bg-gray-800"
              }`}
            >
              <span className="mr-3 text-lg">{item.icon}</span>
              {item.name}
            </Link>
          ))}
        </nav>

        {/* Déconnexion */}
        <div className="mt-auto">
          <button className="logout-button text-sm text-gray-400 hover:text-white hover:bg-red-700 transition w-full">
            <FaSignOutAlt className="mr-3" />
            Déconnexion
          </button>
        </div>
      </div>

      {/* Overlay pour fermer la sidebar sur mobile */}
      {isOpen && <div className="fixed inset-0 bg-black opacity-50 md:hidden" onClick={toggleSidebar}></div>}
    </>
  );
}