import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";

const data = [
  { name: "13-17", value: 20 },
  { name: "18-24", value: 50 },
  { name: "25-34", value: 17 },
  { name: "35+", value: 13 },
];

const COLORS = ["#22d3ee", "#a855f7", "#fb923c", "#a3e635"];

const BarChartHorizontal = () => {
  return (
    <div style={{ width: "100%", maxWidth: 800, margin: "0 auto" }}>
      <div style={{ width: "100%", height: 250 }}>
        <ResponsiveContainer>
          <BarChart
            layout="vertical"
            data={data}
            margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
            barCategoryGap={20}
          >
            {/* On cache l'axe X pour un rendu épuré */}
            <XAxis type="number" domain={[0, 100]} hide />
            <Bar
              dataKey="value"  // Couleur plus foncée pour accentuer le contraste
              radius={[10, 10, 10, 10]}
              barSize={16}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
              <LabelList
                dataKey="value"
                position="right"
                fill="#ffffff"
                formatter={(value) => `${value}%`}
                style={{ fontWeight: "bold", fontSize: 18 }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Légende en bas (affiche uniquement les noms) */}
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10, gap: 18 }}>
        {data.map((entry, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center", color: "white", gap: 5 }}>
            <span
              style={{
                width: "14px",
                height: "14px",
                borderRadius: "50%",
                backgroundColor: COLORS[index],
                display: "inline-block",
              }}
            />
            <span style={{ fontSize: "1.1rem" }}>{entry.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChartHorizontal;
