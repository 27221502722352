import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "../../styles/tailwind.css";
import "../../styles/index.css";

export default function CreationDeComptePage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Vérification de la taille de l'écran
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 1024); // Mobile si ≤ 1024px
    };

    checkScreenSize(); // Exécute au chargement
    window.addEventListener("resize", checkScreenSize); // Met à jour lors des changements

    return () => window.removeEventListener("resize", checkScreenSize); // Nettoyage
  }, []);

  return (
    <>
      <Helmet>
        <title>Créer un compte</title>
        <meta name="description" content="Inscris-toi pour créer ton compte sur notre plateforme." />
      </Helmet>

      <div className="w-full min-h-screen bg-[#0A001B] flex">
        {/* Image affichée uniquement sur Desktop */}
        {!isMobile && (
          <div className="hidden lg:block lg:w-2/5 h-screen sticky top-0 left-0">
            <img src="/images/img_rectangle_122.png" alt="Gamer Image" className="w-full h-full object-cover" />
          </div>
        )}

        {/* Section Formulaire */}
        <div className={`flex flex-col justify-center items-center w-full min-h-screen ${isMobile ? "px-6" : "lg:w-3/5 lg:px-16 ml-auto"}`}>
          <h1 className="text-white text-4xl font-bold mb-6">Créer un compte</h1>
          
          {/* Formulaire */}
          <form className="w-full max-w-md flex flex-col gap-4">
            <input
              type="email"
              placeholder="Email"
              className="w-full h-12 rounded-md px-4 bg-gray-800 text-white border border-gray-700 focus:outline-none focus:border-orange-500"
            />
            <input
              type="password"
              placeholder="Mot de Passe"
              className="w-full h-12 rounded-md px-4 bg-gray-800 text-white border border-gray-700 focus:outline-none focus:border-orange-500"
            />
            <input
              type="password"
              placeholder="Confirmation du Mot de Passe"
              className="w-full h-12 rounded-md px-4 bg-gray-800 text-white border border-gray-700 focus:outline-none focus:border-orange-500"
            />

            {/* Bouton redirection vers "AjoutInfos" */}
            <Link to="/ajout-infos">
              <button className="w-full h-12 rounded-md bg-orange-600 text-white font-semibold hover:bg-orange-700 transition duration-300">
                Continuer
              </button>
            </Link>

            {/* Lien Connexion */}
            <p className="text-center text-gray-400 text-sm mt-2">
              Vous avez déjà un compte ? <Link to="/connexion" className="text-orange-600 hover:underline">Connexion</Link>
            </p>

            {/* Séparateur */}
            <div className="flex items-center space-x-4 my-4">
              <div className="flex-grow h-px bg-gray-600"></div>
              <span className="text-gray-400 text-sm">ou</span>
              <div className="flex-grow h-px bg-gray-600"></div>
            </div>

            {/* Boutons de connexion avec Google et Apple */}
            <button className="flex items-center justify-center w-full h-12 border-2 border-orange-600 rounded-md bg-gray-800 hover:bg-gray-700 text-white transition duration-300">
              <img src="/images/googlelogo.png" alt="Google" className="h-6 mr-2" />
              Continuer avec Google
            </button>
            <button className="flex items-center justify-center w-full h-12 border-2 border-orange-600 rounded-md bg-gray-800 hover:bg-gray-700 text-white transition duration-300">
              <img src="/images/applelogo.png" alt="Apple" className="h-6 mr-2" />
              Continuer avec Apple
            </button>
          </form>

          {/* Lien vers CGU */}
          <p className="mt-6 text-xs text-orange-600">
            Politique de confidentialité | CGU
          </p>
        </div>
      </div>
    </>
  );
}
