export const platforms = [
  {
    src: "/images/red-youtube-logo-symbol-701751694792489qxkccchok1.png",
    alt: "YouTube",
    stats: { followers: "339 M", engagementRate: "ER : 12%" }
  },
  {
    src: "/images/instagram.png",
    alt: "Instagram",
    stats: { followers: "63 M", engagementRate: "ER : 12%" }
  },
  {
    src: "/images/Tiktoklogo.png",
    alt: "TikTok",
    stats: { followers: "106 M", engagementRate: "ER : 12%" }
  },
  {
    src: "/images/facebook.png",
    alt: "Facebook",
    stats: { followers: "27 M", engagementRate: "ER : 12%" }
  },
  {
    src: "/images/logo-twitch-49199.png",
    alt: "Twitch",
    stats: { followers: "574 K", engagementRate: "ER : 12%" }
  },
  {
    src: "/images/X_logo_2023__white_.png",
    alt: "X",
    stats: { followers: "31 M", engagementRate: "ER : 12%" }
  }
];

export const instagramPrices = [
  { label: "Stories", price: "150€" },
  { label: "Post", price: "350€" },
  { label: "Post + Stories", price: "450€" },
  { label: "Post Jeu-concours", price: "250€" },
  { label: "Reel", price: "450€" },
];

export const youtubePrices = [
  { label: "Vidéo-sponso", price: "450€" },
  { label: "Vidéo dédiée", price: "800€" },
];

export const twitchPrices = [
  { label: "Présence sur la bio", price: "150€" },
  { label: "Logo à l'écran", price: "150€" },
  { label: "Élément dans le décor", price: "150€" },
  { label: "Jeu-concours live", price: "150€" },
];

export const tiktokPrices = [
  { label: "Vidéo-sponsor", price: "550€" },
  { label: "Vidéo dédiée", price: "1000€" },
];

export const facebookPrices = [
  { label: "Stories", price: "150€" },
  { label: "Post", price: "350€" },
  { label: "Post + Stories", price: "450€" },
  { label: "Reel", price: "150€" },
];