import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";

export default function Assistance() {
  const [form, setForm] = useState({
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
    categorie: "Problème technique",
    sujet: "",
    description: "",
    priorite: "normale", // valeurs possibles : "faible", "normale", "urgente"
  });
  
  const [attachments, setAttachments] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setAttachments(e.target.files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logique de soumission du formulaire d'assistance
    console.log("Formulaire Assistance :", form);
    console.log("Pièce(s) jointe(s) :", attachments);
    // Vous pouvez ici ajouter vos appels API pour envoyer la demande d'assistance
  };

  return (
    <div className="flex min-h-screen bg-[#0A001B] text-white">
      {/* Sidebar (identique au Dashboard) */}
      <Sidebar />

      {/* Contenu principal */}
      <div className="w-full p-10 overflow-auto">
        <h1 className="text-3xl font-bold mb-8">Assistance</h1>
        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Informations utilisateur */}
          <div className="bg-[#18122B] p-6 rounded-lg shadow-sm">
            <h2 className="text-2xl font-bold mb-4">Informations utilisateur</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block mb-1 font-semibold">Nom</label>
                <input
                  type="text"
                  name="nom"
                  value={form.nom}
                  onChange={handleChange}
                  placeholder="Votre nom"
                  className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">Prénom</label>
                <input
                  type="text"
                  name="prenom"
                  value={form.prenom}
                  onChange={handleChange}
                  placeholder="Votre prénom"
                  className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">Adresse email</label>
                <input
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  placeholder="exemple@domaine.com"
                  className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">Numéro de téléphone (optionnel)</label>
                <input
                  type="text"
                  name="telephone"
                  value={form.telephone}
                  onChange={handleChange}
                  placeholder="+33 6 12 34 56 78"
                  className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                />
              </div>
            </div>
          </div>

          {/* Détails de la demande */}
          <div className="bg-[#18122B] p-6 rounded-lg shadow-sm">
            <h2 className="text-2xl font-bold mb-4">Détails de la demande</h2>
            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="block mb-1 font-semibold">Catégorie du problème</label>
                <select
                  name="categorie"
                  value={form.categorie}
                  onChange={handleChange}
                  className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                >
                  <option value="Problème technique">Problème technique</option>
                  <option value="Facturation">Facturation</option>
                  <option value="Question générale">Question générale</option>
                  <option value="Autres">Autres</option>
                </select>
              </div>
              <div>
                <label className="block mb-1 font-semibold">Sujet</label>
                <input
                  type="text"
                  name="sujet"
                  value={form.sujet}
                  onChange={handleChange}
                  placeholder="Objet de votre demande"
                  className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">Description du problème</label>
                <textarea
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  placeholder="Décrivez en détail votre demande ou problème"
                  className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                  rows="5"
                ></textarea>
              </div>
              <div>
                <label className="block mb-1 font-semibold">Pièce(s) jointe(s)</label>
                <input
                  type="file"
                  name="attachments"
                  onChange={handleFileChange}
                  multiple
                  className="w-full p-2 rounded bg-[#0A001B] border border-gray-600"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">Priorité</label>
                <div className="flex items-center space-x-4">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="priorite"
                      value="faible"
                      checked={form.priorite === "faible"}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <label>Faible</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="priorite"
                      value="normale"
                      checked={form.priorite === "normale"}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <label>Normale</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="priorite"
                      value="urgente"
                      checked={form.priorite === "urgente"}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <label>Urgente</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Bouton de soumission */}
          <div className="text-right">
            <button
              type="submit"
              className="px-6 py-2 bg-purple-600 rounded-md font-semibold hover:bg-purple-700"
            >
              Envoyer la demande
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}