import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CreationDeComptePage from "./pages/Creationdecompte";
import AjoutInfosPage from "./pages/AjoutInfos";
import Connexion from "./pages/Connexion";
import Kitmedia from "./pages/Kitmedia";
import Reglages from "./pages/Reglages";
import Assistance from "./pages/Assistance";
import Dashboard from "./pages/Dashboard";
import ConnexionRS from "./pages/ConnexionRS";
import MotDePasseOublie from "./pages/mdpoublie";
import VerificationCodePage from "./pages/mdpoubliecode";
import NouveauMotDePassePage from "./pages/nouveaumdp";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CreationDeComptePage />} />
        <Route path="/ajout-infos" element={<AjoutInfosPage />} />
        <Route path="/connexion" element={<Connexion />} />
        <Route path="/kitmedia" element={<Kitmedia />} />
        <Route path="/reglages" element={<Reglages />} />
        <Route path="/assistance" element={<Assistance />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/connexion-rs" element={<ConnexionRS />} />
        <Route path="/mdp-oublie" element={<MotDePasseOublie />} />
        <Route path="/mdp-oublie-code" element={<VerificationCodePage />} />
        <Route path="/nouveau-mdp" element={<NouveauMotDePassePage />} />
      </Routes>
    </Router>
  );
}

export default App;
