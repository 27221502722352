import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/tailwind.css";
import "../../styles/index.css";

export default function VerificationCodePage() {
  const [isMobile, setIsMobile] = useState(false);
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Gestion des entrées du code OTP
  const handleChange = (index, e) => {
    const value = e.target.value;
    if (!/^[0-9]?$/.test(value)) return;

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !code[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerification = () => {
    const enteredCode = code.join("");
    if (enteredCode.length === 6) {
      // Vérification réussie → redirection vers la création d’un nouveau mot de passe
      navigate("/nouveau-mot-de-passe");
    } else {
      alert("Code incorrect ou incomplet.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Vérification du code</title>
        <meta name="description" content="Entrez le code de vérification envoyé à votre email." />
      </Helmet>

      <div className="w-full min-h-screen bg-[#0A001B] flex">
        {/* Image fixe sur desktop */}
        {!isMobile && (
          <div className="hidden lg:block lg:w-2/5 h-screen sticky top-0 left-0">
            <img
              src="/images/img_rectangle_122.png"
              alt="Gamer Image"
              className="w-full h-full object-cover"
            />
          </div>
        )}

        {/* Contenu principal */}
        <div className={`flex flex-col justify-center items-center w-full min-h-screen ${isMobile ? "px-6" : "lg:w-3/5 lg:px-16 ml-auto"}`}>
          <h1 className="text-white text-3xl font-bold mb-4 text-center">
            Mot de passe oublié ?
          </h1>

          <p className="text-gray-400 text-sm text-center mb-4">
            Entrez le code reçu à l’adresse mail suivante : <span className="text-white">squ********@hotmail.com</span>
          </p>

          {/* Saisie du code OTP */}
          <div className="flex justify-center space-x-3 mb-6">
            {code.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="w-12 h-12 text-xl text-center bg-gray-800 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-600"
                value={digit}
                onChange={(e) => handleChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>

          {/* Bouton Vérifier */}
          <button
            className="w-full max-w-md h-12 rounded-md bg-orange-600 text-white font-semibold hover:bg-orange-700 transition mb-4"
            onClick={handleVerification}
          >
            Vérifier
          </button>

          <p className="text-gray-400 text-sm">
            Vous n’avez pas reçu de code ?{" "}
            <span className="text-orange-600 cursor-pointer hover:underline">Renvoyer</span>
          </p>

          <p className="mt-6 text-xs text-orange-600">
            Politique de confidentialité | CGU
          </p>
        </div>
      </div>
    </>
  );
}
