import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer
} from "recharts";

/* Données Pays */
const paysData = [
  { name: "France", value: 80 },
  { name: "Belgique", value: 15 },
  { name: "Canada", value: 5 },
];

/* Données Villes */
const villeData = [
  { name: "Paris", value: 70 },
  { name: "Lyon", value: 20 },
  { name: "Bordeaux", value: 10 },
];

/* Couleurs distinctes pour chaque ensemble */
const paysColors = ["#22d3ee", "#a855f7", "#fb923c"];
const villeColors = ["#22d3ee", "#a855f7", "#fb923c"];

/* Composant pour afficher le label sur chaque part en blanc */
const renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const RADIAN = Math.PI / 180;
  // Calcul de la position du label (placé à l'intérieur de la part)
  const radius = innerRadius + (outerRadius - innerRadius) / 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      style={{ fontWeight: "bold", fontSize: "14px" }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function PieOfPieChart() {
  return (
    <div style={{ width: "100%", maxWidth: 800, margin: "0 auto", color: "white" }}>
      {/* Conteneur en flex pour afficher côte à côte les deux camemberts */}
      <div style={{ display: "flex", justifyContent: "space-around", gap: "2rem" }}>
        {/* Premier camembert (Pays) */}
        <div style={{ flex: 1 }}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Tooltip />
              <Pie
                data={paysData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}   // Camembert réduit de 20%
                innerRadius={50}
                label={renderLabel}
                labelLine={false}
              >
                {paysData.map((entry, index) => (
                  <Cell key={`pays-${index}`} fill={paysColors[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          {/* Légende pour Pays (affichant uniquement les noms) */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              fontSize: "1rem",
              gap: "20px"
            }}
          >
            {paysData.map((entry, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div
                  style={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "50%",
                    backgroundColor: paysColors[index],
                    display: "inline-block",
                  }}
                ></div>
                <span>{entry.name}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Second camembert (Villes) */}
        <div style={{ flex: 1 }}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Tooltip />
              <Pie
                data={villeData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}   // Même taille que le camembert des Pays
                innerRadius={50}
                label={renderLabel}
                labelLine={false}
              >
                {villeData.map((entry, index) => (
                  <Cell key={`ville-${index}`} fill={villeColors[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          {/* Légende pour Villes (affichant uniquement les noms) */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              fontSize: "1rem",
              gap: "20px"
            }}
          >
            {villeData.map((entry, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div
                  style={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "50%",
                    backgroundColor: villeColors[index],
                    display: "inline-block",
                  }}
                ></div>
                <span>{entry.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
