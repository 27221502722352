import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "../../styles/tailwind.css";
import "../../styles/index.css";

export default function ConnexionPage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Vérifie la taille de l'écran pour déterminer si c'est mobile
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <>
      <Helmet>
        <title>Connexion</title>
        <meta
          name="description"
          content="Connecte-toi à ton compte pour accéder à la plateforme."
        />
      </Helmet>

      <div className="w-full min-h-screen bg-[#0A001B] flex">
        {/* Image fixe sur desktop/tablette */}
        {!isMobile && (
          <div className="hidden lg:block lg:w-2/5 h-screen sticky top-0 left-0">
            <img
              src="/images/img_rectangle_122.png"
              alt="Gamer Image"
              className="w-full h-full object-cover"
            />
          </div>
        )}

        {/* Contenu principal */}
        <div
          className={`flex flex-col justify-center items-center w-full min-h-screen ${
            isMobile ? "px-6" : "lg:w-3/5 lg:px-16 ml-auto"
          }`}
        >
          <h1 className="text-white text-3xl font-bold mb-6 text-center">
            Bienvenue à nouveau
          </h1>

          {/* Boutons de connexion rapide */}
          <div className="flex flex-col w-full max-w-md space-y-3 mb-6">
            <button className="flex items-center justify-center w-full h-12 border-2 border-orange-600 rounded-md bg-gray-800 hover:bg-gray-700 text-white transition">
              <img src="/images/googlelogo.png" alt="Google" className="h-5 mr-2" />
              Continuer avec Google
            </button>
            <button className="flex items-center justify-center w-full h-12 border-2 border-orange-600 rounded-md bg-gray-800 hover:bg-gray-700 text-white transition">
              <img src="/images/applelogo.png" alt="Apple" className="h-5 mr-2" />
              Continuer avec Apple
            </button>
          </div>

          {/* Séparateur */}
          <div className="flex items-center w-full max-w-md mb-6">
            <div className="flex-grow h-px bg-gray-600"></div>
            <span className="mx-2 text-gray-400 text-sm">ou</span>
            <div className="flex-grow h-px bg-gray-600"></div>
          </div>

          {/* Formulaire de connexion */}
          <form className="w-full max-w-md space-y-3">
            <input
              type="email"
              placeholder="Email"
              className="w-full h-12 rounded-md px-4 bg-gray-800 text-white border border-gray-700"
            />
            <input
              type="password"
              placeholder="Mot de Passe"
              className="w-full h-12 rounded-md px-4 bg-gray-800 text-white border border-gray-700"
            />

            <div className="w-full text-right">
              <Link
                to="/mdp-oublie"
                className="text-orange-600 text-sm cursor-pointer hover:underline"
              >
                Mot de passe oublié ?
              </Link>
            </div>

            <button className="w-full h-12 rounded-md bg-orange-600 text-white font-semibold hover:bg-orange-700 transition">
              Continuer
            </button>
          </form>

          {/* Lien vers l'inscription */}
          <p className="mt-4 text-gray-400 text-sm">
            Vous n’avez pas de compte ?{" "}
            <Link to="/" className="text-orange-600 hover:underline">
              Inscription
            </Link>
          </p>

          <p className="mt-6 text-xs text-orange-600">
            Politique de confidentialité | CGU
          </p>
        </div>
      </div>
    </>
  );
}
