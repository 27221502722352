import React from 'react';

const StatCard = ({ icon, title, children }) => (
  <div className="bg-[#232323] p-6 rounded-lg relative flex flex-col justify-between">
    <div className="absolute top-2 right-2 text-xl">{icon}</div>
    <h3 className="text-lg font-bold mb-2">{title}</h3>
    {children}
  </div>
);

export default StatCard;